'use client';
import categories from '@c/navigation/utils/categories';
import SportCard from './components/SportCard';
import Link from 'next/link';

export default function ShopBySport({
  variant = 'main',
}: {
  variant: 'riding-gear' | 'parts' | 'main';
}) {
  const sportCards = categories
    .filter((cat) => cat.label !== 'Casual' && cat.label !== 'Collectibles')
    .map((category) => {
      let slug = category.href;
      if (variant === 'riding-gear') {
        if (slug.endsWith('s')) slug = slug.slice(0, -1);
        slug = slug.concat('-riding-gear');
      } else if (variant === 'parts') {
        if (slug.endsWith('s')) slug = slug.slice(0, -1);
        slug = slug.concat('-parts');
      }
      return (
        <div key={category.label}>
          <Link href={slug}>
            <SportCard sport={category} />
          </Link>
        </div>
      );
    });

  return (
    <div className={`z-20 flex h-auto gap-[1.6rem] sm:overflow-hidden`}>
      {sportCards.map((card, index) => (
        <div key={index}>{card}</div>
      ))}
    </div>
  );
}
