import categories from '@c/navigation/utils/categories';
import Link from 'next/link';

export default function CategoryLinks() {
  return (
    <div className="w-full bg-brand-darker-white">
      <div className="mx-auto flex max-w-[155rem] flex-col gap-12 px-4 py-16 sm:px-0">
        {categories
          .filter((cat) => !cat.excludeFromNav)
          .map((category) => (
            <div key={category.href}>
              <Link
                href={category.href}
                className="text-[1.8rem] font-semibold "
              >
                Shop {category.label}
              </Link>
              <div className="grid grid-cols-2 gap-4 p-8 sm:grid-cols-6">
                {category.sections
                  ?.map((section) => section.links)
                  .flat()
                  .map((link) => {
                    if (!link) return null;
                    return (
                      <Link key={link.href} href={link.href}>
                        {link.label}
                      </Link>
                    );
                  })}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
